<template>
    <v-row justify="center" class="pa-8">
      <v-col cols="12" class="d-flex justify-center pa-0 mb-4">
        <BaseSvg :max-width=" width < 600 ? '300': '500'" name="texto-3"/>
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="6" class="pa-0">
        <p class="text text-top">
          Captar novos clientes nunca foi tão fácil e rápido.
        </p>
        <br />
        <p class="text">
          Acompanhe seus clientes, entre em contato através do Whatsapp com
          essa poderosa ferramenta de CRM.
        </p>
        <br />
        <p class="text">Seu histórico de atendimento sempre com você.</p>
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="6" class="pa-0 d-flex justify-center">
        <v-img
          contain
          :max-width=" width < 600 ? '300': '600'"
          :src="require('../assets/img/lead-crm.png')"
        ></v-img>
      </v-col>
    </v-row>
</template>

<script>
import BaseSvg from './BaseSvg';

export default {
  name: 'TheSectionLead',

  components: {
    BaseSvg,
  },

  computed: {
    width() {
      return this.$vuetify.breakpoint.width;
    },
  },
};
</script>

<style scoped>
.text {
  color: black;
  font-style: italic;
  font-size: 1.2em;
  font-weight: 600;
}

.text-top {
  margin-top: 30px;
}

@media (max-width: 599.98px) {
.text {
  color: black;
  font-style: italic;
  font-size: 1em;
  font-weight: 600;
}
}
</style>
