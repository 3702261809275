<template>
  <v-col cols="12">
    <v-row justify="center">
      <v-col class="mt-5 d-flex justify-center" cols="12">
        <BaseSvg :max-width=" width < 600 ? '300': '350'" name="texto-1" />
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="12" sm="6" md="6" lg="6">
            <v-img
              class="ml-5"
              max-width="260"
              :src="require('../assets/img/desempenho.png')">
            </v-img>
          </v-col>
          <v-col cols="12" sm="5" md="5" lg="5" class="text-top">
            <p class="text">
              Deseja acompanhar seus ganhos em tempo
              real e verificar suas metas?
            </p>
            <br />
            <p class="text">
              Agora é possivel visualizar seu desempenho
              em tempo real e acompanhar seu rendimento mensal.
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import BaseSvg from './BaseSvg';

export default {
  name: 'TheSectionDesempenho',

  components: {
    BaseSvg,
  },

  computed: {
    width() {
      return this.$vuetify.breakpoint.width;
    },
  },
};
</script>

<style scoped>
.text {
  font-style: italic;
  font-size: 1.2em;
  text-align: initial;
  font-weight: 600;
  color: black;
}

.text-top {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 599.98px) {
  .text {
  font-style: italic;
  font-size: 1.2em;
  text-align: initial;
  font-weight: 600;
  color: black;
  margin-left: 10px;
}
}
</style>
