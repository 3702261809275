import { render, staticRenderFns } from "./TheSectionDesempenho.vue?vue&type=template&id=63b014fa&scoped=true&"
import script from "./TheSectionDesempenho.vue?vue&type=script&lang=js&"
export * from "./TheSectionDesempenho.vue?vue&type=script&lang=js&"
import style0 from "./TheSectionDesempenho.vue?vue&type=style&index=0&id=63b014fa&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63b014fa",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VImg,VRow})
