<template>
    <v-row justify="center" class="pa-8">
      <v-col cols="12" class="d-flex justify-center">
        <BaseSvg :max-width=" width < 600 ? '300': '400'" name="texto-4" />
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="6">
        <v-img
          contain
          max-height="350"
          max-width="300"
          :src="require('../assets/img/simulacao.png')"
        ></v-img>
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="6" class="text-top">
        <p class="text">
          Consórcio ou Financiamento? Tanto faz, aqui você garante um
          orçamento claro e objetivo, com todas as informações que você
          precisa.
        </p>
        <br />
        <p class="text">
          Aproveite e compartilhe suas simulações com seus leads e clientes
          lhes oferecendo mais comodidade.
        </p>
      </v-col>
    </v-row>
</template>

<script>
import BaseSvg from './BaseSvg';

export default {
  name: 'TheSectionSimulacao',

  components: {
    BaseSvg,
  },

  computed: {
    width() {
      return this.$vuetify.breakpoint.width;
    },
  },
};
</script>

<style scoped>
.text {
  color: black;
  font-style: italic;
  font-size: 1.2em;
  font-weight: 600;
  padding-right: 50px;
}

.text-top {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 599.98px) {
.text {
  color: black;
  font-style: italic;
  font-size: 1em;
  font-weight: 600;
  padding-right: 50px;
}
}
</style>
