<template>
  <v-col cols="12">
    <v-img height="100%" width="100%" :src="require('../assets/img/relatorios.png')">
    <v-row>
      <v-col cols="0" sm="6" md="6" lg="6"></v-col>
      <v-col cols="12" sm="6" md="6" lg="6" class="titulo">
        <BaseSvg :max-width=" width < 600 ? '300': '350'" name="texto-2" />
      </v-col>
    </v-row>
      <v-row>
        <v-col cols="4" sm="6" md="6" lg="6"></v-col>
        <v-col class="mt-5" cols="6" sm="6" md="6" lg="6">
          <p class="text">
            Através do seu histórico de vendas, você <br />
            pode simular seus ganhos futuros.
          </p>
          <br />
          <p class="text">Vamos planejar suas vendas?</p>
        </v-col>
      </v-row>
    </v-img>
  </v-col>
</template>

<script>
import BaseSvg from './BaseSvg';

export default {
  name: 'TheSectionNegocio',

  components: {
    BaseSvg,
  },

  computed: {
    width() {
      return this.$vuetify.breakpoint.width;
    },
  },
};
</script>

<style scoped>
.text {
  color: white;
  font-style: italic;
  font-size: 1.2em;
  font-weight: 500;
}

.titulo{
  margin-top: 64px;
}

@media (max-width: 599.98px) {
  .titulo{
  margin-top: -10px;
  display: flex;
  justify-content: center;

}

.text {
  color: white;
  font-style: italic;
  font-size: 1em;
  font-weight: 500;
}
}
</style>
