<template>
  <v-btn
    class="ma-0 pa-0"
    target="_blank"
    href="https://play.google.com/store/apps/details?id=br.com.flyvendas&hl=pt_BR&gl=US"
    text
  >
    <v-img
      :max-width="maxWidth"
      :src="require('../assets/img/google-play.png')"
    ></v-img>
  </v-btn>
</template>

<script>
export default {
  name: 'BaseGooglePlayLink',

  props: {
    maxWidth: {
      type: String,
    },
  },
};
</script>
