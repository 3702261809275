<template>
  <v-img height="100%" width="100%" :src="require('../assets/img/background-footer.png')">
    <v-row justify="center" class="mt-16">
      <v-col cols="12" class="mt-4 d-flex flex-column justify-center">
        <BaseSvg :max-width=" width < 600 ? '300': '350'" name="texto-5" class="texto-5" />
        <h3 class="text-subtitle">Com essa ferramenta você vende</h3>
      </v-col>
      <v-col cols="12">
        <v-row justify="center">
          <v-col cols="9">
            <v-img
              contain
              max-width="800"
              class="mt-5"
              :src="require('../assets/img/flyvendas-telas.png')"
            ></v-img>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" class="mt-5">
        <v-row justify="center">
          <BaseGooglePlayLink class="mr-10" max-width="117" v-if="show" />
          <BaseAppStoreLink class="ml-10" max-width="108" v-if="showMacIOS" />
        </v-row>
      </v-col>

      <v-col cols="12" class="mt-12">
        <v-row justify="center" align="center">
                <BaseSvg max-width="120" name="logo-ti-negativo" />
                <v-divider class="mr-5 ml-5" inset vertical></v-divider>
                <BaseSvg max-width="90" name="logo-grupocometa-negativo" />
                <v-divider class="mr-5 ml-5" inset vertical></v-divider>
          <v-btn
            icon
            target="_blank"
            href="https://www.instagram.com/grupocometaoficial/"
          >
            <BaseSvg max-width="20" name="icon-instagram" />
          </v-btn>

          <v-btn
            icon
            target="_blank"
            href="https://www.facebook.com/cometamotocentercaceres1"
          >
            <BaseSvg max-width="20" name="icon-facebook" />
          </v-btn>

          <v-btn icon target="_blank" href="https://wa.me/+5565999897910">
            <BaseSvg max-width="20" name="icon-whatsapp" />
          </v-btn>

          <v-btn
            icon
            target="_blank"
            href="https://www.linkedin.com/company/grupocometaoficial/"
          >
            <BaseSvg max-width="20" name="icon-linkedin" />
          </v-btn>

          <v-btn
            icon
            target="_blank"
            href="https://www.youtube.com/user/viacometa/videos"
          >
            <BaseSvg class="ml-3" max-width="30" name="icon-youtube" />
          </v-btn>
        </v-row>
          </v-col>
    </v-row>
  </v-img>
</template>

<script>
import BaseAppStoreLink from './BaseAppStoreLink';
import BaseGooglePlayLink from './BaseGooglePlayLink';
import BaseSvg from './BaseSvg';

export default {
  name: 'Footer',

  components: {
    BaseAppStoreLink,
    BaseGooglePlayLink,
    BaseSvg,
  },

  data() {
    return {
      showMacIOS: false,
      show: false,
    };
  },

  computed: {
    width() {
      return this.$vuetify.breakpoint.width;
    },
  },

  methods: {
    showButtons() {
      // eslint-disable-next-line eqeqeq
      if (window.navigator.userAgent.indexOf('Mac') != -1) {
        this.show = false;
        this.showMacIOS = true;
      } else {
        this.show = true;
        this.showMacIOS = false;
      }
    },
  },

  mounted() {
    this.showButtons();
  },
};
</script>

<style scoped>
.div-background {
  background: #41a4ef;
}

.texto-5{
  margin: 0 auto;
}

.text-subtitle {
  color: white;
  text-align: center;
  font-style: italic;
  font-size: 16px;
  font-weight: 500;
  margin-right: 20px;
}

.theme--light .v-divider {
  border-color: white;
}

.v-divider--vertical {
  margin: 8px;
}
</style>
