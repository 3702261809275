<template>
  <v-img height="100%" width="100%" :src="require('../assets/img/business-charts-background.png')">
    <v-row align="center">
      <v-col cols="12" sm="6" md="6" lg="6">
        <v-row justify="center">
          <v-col cols="12">
            <BaseSvg class="aling-componentes logo-flyvendas" name="logo-flyvendas" />
          </v-col>
          <v-col cols="12" class="aling-componentes">
            <p class="text">
              Tenha em mãos todos
            </p>
            <p class="text">
              seus clientes. Acompanhe seu
            </p>
            <p class="text">
              desempenho e veja seus lucros
            </p>
          </v-col>
          <v-col cols="12">
            <v-row class="logo-stores">
              <v-col cols="12" class="stores" v-if="show">
                <BaseGooglePlayLink :max-width="width < 600 ? '45%' : '65%'" />
              </v-col>
              <v-col cols="12" class="stores" v-if="showMacIOS">
                <BaseAppStoreLink :max-width="width < 600 ? '45%' : '65%'" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="m-0 p-0 stores d-none d-sm-flex" cols="6" sm="6" md="6" lg="6">
        <v-img contain max-width="290" :src="require('../assets/img/home.png')"></v-img>
      </v-col>
    </v-row>
  </v-img>
</template>

<script>
import BaseAppStoreLink from './BaseAppStoreLink';
import BaseGooglePlayLink from './BaseGooglePlayLink';
import BaseSvg from './BaseSvg';

export default {
  name: 'Header',

  components: {
    BaseAppStoreLink,
    BaseGooglePlayLink,
    BaseSvg,
  },

  data() {
    return {
      showMacIOS: true,
      show: true,
    };
  },

  computed: {
    width() {
      return this.$vuetify.breakpoint.width;
    },
  },

  methods: {
    showButtons() {
      // eslint-disable-next-line eqeqeq
      if (window.navigator.userAgent.indexOf('Mac') != -1) {
        this.show = false;
        this.showMacIOS = true;
      } else {
        this.show = true;
        this.showMacIOS = false;
      }
    },
  },

  mounted() {
    this.showButtons();
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@1,100&display=swap");
.text {
  font-family: "Roboto", sans-serif;
  color: white;
  font-style: italic;
  font-size: 1.8em;
  text-align: center;
  margin-right: 190px;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

.logo-flyvendas {
  max-width: 50%;
}

.logo-store {
  max-width: 65% !important;
}

.aling-componentes {
  margin: 0 auto;
}

.stores {
  display: flex;
  justify-content: center;
}

@media (max-width: 599.98px) {
  .text {
    font-family: "Roboto", sans-serif;
    color: white;
    font-style: italic;
    font-size: 1.2em;
    text-align: center;
    margin-right: 190px;
    font-weight: 400;
    margin: -10px;
    padding: 0;
  }

  .logo-flyvendas {
    max-width: 30%;
    margin-top: 10px;
    margin-bottom: -15px;
  }

  .logo-stores {
    margin-top: -30px;
  }
}
</style>
