<template>
  <v-row>
    <TheSectionDesempenho />
    <TheSectionNegocio />
    <TheSectionLead />
    <TheSectionSimulacao />
  </v-row>
</template>

<script>
import TheSectionDesempenho from './TheSectionDesempenho';
import TheSectionNegocio from './TheSectionNegocio';
import TheSectionLead from './TheSectionLead';
import TheSectionSimulacao from './TheSectionSimulacao';

export default {
  name: 'Content',

  components: {
    TheSectionDesempenho,
    TheSectionNegocio,
    TheSectionLead,
    TheSectionSimulacao,
  },
};
</script>
