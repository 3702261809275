<template>
  <v-img :max-width="maxWidth" :src="svg"></v-img>
</template>

<script>
export default {
  /* eslint-disable global-require */
  /* eslint-disable import/no-dynamic-require */
  name: 'BaseSvg',

  props: {
    name: {
      type: String,
      require: true,
    },
    maxWidth: {
      type: String,
    },
  },

  data() {
    return {
      svg: '',
    };
  },

  created() {
    this.svg = require(`../assets/img/${this.name}.svg`);
  },
};
</script>
