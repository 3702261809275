<template>
  <v-btn target="_blank" class="ma-0 pa-0" text>
    <v-img
      @click="download()"
      :max-width="maxWidth"
      :src="require('../assets/img/app-store.png')"
    ></v-img>
  </v-btn>
</template>

<script>
import axios from 'axios';

export default {
  name: 'BaseAppStoreLink',
  props: {
    maxWidth: {
      type: String,
    },
  },
  methods: {
    async download() {
      try {
        const response = await axios.get('https://api.viacometa.com.br/apiV2/flyvendas/download');
        const link = response.data;
        window.location.replace(link);
      } catch (error) {
        alert('Erro ao acessar link, contate seu suporte');
      }
    },
  },
};
</script>
